import 'core-js/stable'
import Vue from 'vue'

import App from './App.vue'
import router from './router'

import {
  Button,
  Icon,
  Field,
  Input,
  Switch,
  Select,
  Checkbox,
  Tabs,
  Loading,
  Collapse,
  Numberinput,
} from 'buefy'

import './styles/styles.scss'

Vue.use(Button)
Vue.use(Icon)
Vue.use(Field)
Vue.use(Loading)
Vue.use(Input)
Vue.use(Switch)
Vue.use(Select)
Vue.use(Input)
Vue.use(Checkbox)
Vue.use(Tabs)
Vue.use(Collapse)
Vue.use(Numberinput)

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#dashboard')
