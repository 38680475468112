<template>
  <div class="theme-form">
    <div class="columns">
      <div class="column">
        <b-field label="Theme Preset">
          <b-select
            v-model="themePreset"
            placeholder="Select a Theme preset..."
            expanded
            @input="onThemePresetSelected"
          >
            <option
              v-for="preset of themePresets"
              :key="preset.chatbotName"
              :value="preset"
            >
              {{ preset.chatbotName }}
            </option>
          </b-select>
        </b-field>
      </div>
    </div>

    <hr />

    <div class="columns">
      <div class="column">
        <b-field label="Chatbot Name">
          <b-input v-model="theme.chatbotName" @input="change" />
        </b-field>
      </div>

      <div class="column">
        <b-field label="Position">
          <b-select
            v-model="theme.position"
            placeholder="Position"
            expanded
            @input="change"
          >
            <option value="right">Right</option>
            <option value="left">Left</option>
          </b-select>
        </b-field>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <b-field label="Avatar Base64">
          <b-input v-model="theme.avatar" @input="change" />
        </b-field>
      </div>

      <div class="column">
        <b-field label="Avatar Preview">
          <div class="logo-preview-wrapper" :style="imagePreviewStyle">
            <div class="logo-preview" :style="avatarStyle" />
          </div>
        </b-field>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <b-field label="Logo Base64">
          <b-input v-model="theme.logo" @input="change" />
        </b-field>
      </div>

      <div class="column">
        <b-field label="Logo Preview">
          <div class="company-logo-preview-wrapper" :style="imagePreviewStyle">
            <div class="company-logo-preview" :style="logoStyle" />
          </div>
        </b-field>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <b-field label="Primary Color">
          <b-input v-model="theme.primaryColor" type="color" @input="change" />
        </b-field>
      </div>

      <div class="column">
        <b-field label="Primary Text Color">
          <b-input
            v-model="theme.primaryTextColor"
            type="color"
            @input="change"
          />
        </b-field>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <b-field label="Secondary Color">
          <b-input
            v-model="theme.secondaryColor"
            type="color"
            @input="change"
          />
        </b-field>
      </div>

      <div class="column">
        <b-field label="Secondary Text Color">
          <b-input
            v-model="theme.secondaryTextColor"
            type="color"
            @input="change"
          />
        </b-field>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <b-field label="Font Size">
          <b-select
            v-model="theme.fontSize"
            placeholder="Font Size"
            expanded
            @input="change"
          >
            <option
              v-for="size in fontSizes"
              :key="size.value"
              :value="size.value"
            >
              {{ size.name }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="column">
        <b-field label="Font">
          <b-select
            v-model="theme.font"
            placeholder="Fon"
            expanded
            @input="change"
          >
            <option v-for="font in fonts" :key="font.value" :value="font.value">
              {{ font.name }}
            </option>
          </b-select>
        </b-field>
      </div>
    </div>

    <!-- TODO add background support -->

    <div class="columns">
      <div class="column">
        <b-field label="Bubble style">
          <b-select
            v-model="theme.bubbleStyle"
            placeholder="Position"
            expanded
            @input="change"
          >
            <option value="default">Default</option>
            <option value="pointy">Pointy tail</option>
          </b-select>
        </b-field>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <b-field label="Branding">
          <b-switch v-model="theme.showPoweredBy" @input="change">
            Powered by aiaibot
          </b-switch>
        </b-field>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <b-field label="Header">
          <b-switch v-model="theme.headerHidden" @input="change">
            Header hidden
          </b-switch>
        </b-field>
      </div>

      <div class="column">
        <b-field label="Launcher">
          <b-switch v-model="theme.launcherHidden" @input="change">
            Launcher hidden
          </b-switch>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import {
  AIAIBOT,
  AIAIBOT_DARK,
  PIDAS,
  MUELLER_MARTINI,
  FRANZ,
  WEBREPUBLIC,
} from '@/dashboard/models/chatbot-themes'
import { FONTS, FONT_SIZES } from '@/chatbot/models/Theme'

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      theme: this.value,
      themePreset: AIAIBOT,
      themePresets: [
        AIAIBOT,
        AIAIBOT_DARK,
        PIDAS,
        MUELLER_MARTINI,
        FRANZ,
        WEBREPUBLIC,
      ],
      fontSizes: FONT_SIZES,
      fonts: Object.keys(FONTS).map((value) => ({ name: value, value })),
    }
  },
  computed: {
    imagePreviewStyle() {
      return {
        backgroundColor: this.theme.primaryColor,
      }
    },
    avatarStyle() {
      if (!this.theme.avatar) {
        return {}
      }

      return {
        backgroundImage: `url(${this.getImageUrl(this.theme.avatar)})`,
      }
    },
    logoStyle() {
      if (!this.theme || !this.theme.logo) {
        return {}
      }

      return {
        backgroundImage: `url(${this.getImageUrl(this.theme.logo)})`,
      }
    },
  },
  methods: {
    change() {
      this.$emit('update', {
        ...this.theme,
      })
    },
    onThemePresetSelected() {
      this.theme = this.themePreset
      this.$emit('update', this.theme)
    },
    getImageUrl(url) {
      if (!url.startsWith('http')) {
        return `data:${url}`
      }

      return url
    },
  },
}
</script>

<style lang="scss">
.logo-preview-wrapper,
.company-logo-preview-wrapper {
  max-height: 44px;
  height: 44px;
  width: 100%;
  background: $grey;
  border-radius: $radius;
  padding: $nudge;
}

.logo-preview,
.company-logo-preview {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
}
</style>
