export const INPUT_TYPE_TEXT = 'text'
export const INPUT_TYPE_EMAIL = 'email'
export const INPUT_TYPE_PHONE = 'phone'
export const INPUT_TYPE_LOCATION = 'location'
export const INPUT_TYPE_HOUSE_NO = 'house_no'
export const INPUT_TYPE_INVALID_LOCATION = 'invalid_location'
export const INPUT_TYPE_CHAT = 'chat'
export const INPUT_TYPE_CHAT_NAME = 'chat-name'
export const INPUT_TYPE_SURVEY = 'survey'
export const INPUT_TYPE_FAQ = 'faq'

export const INPUT_TYPES = [
  INPUT_TYPE_TEXT,
  INPUT_TYPE_EMAIL,
  INPUT_TYPE_PHONE,
  INPUT_TYPE_LOCATION,
  INPUT_TYPE_HOUSE_NO,
  INPUT_TYPE_INVALID_LOCATION,
  INPUT_TYPE_CHAT,
  INPUT_TYPE_CHAT_NAME,
  INPUT_TYPE_SURVEY,
  INPUT_TYPE_FAQ,
]
export const INPUT_TYPE_DATETIME_DATE = 'date'
export const INPUT_TYPE_DATETIME_DATETIME = 'datetime'
export const INPUT_TYPE_DATETIME_DATE_RANGE = 'date_range'
export const INPUT_TYPE_DATETIME_DATETIME_RANGE = 'datetime_range'

export const AUTO_DETECTABLE_INPUT_TYPES = [
  {
    type: INPUT_TYPE_PHONE,
    label: 'form.user.input.phone.autofill.label',
  },
  {
    type: INPUT_TYPE_EMAIL,
    label: 'form.user.input.email.autofill.label',
  },
  {
    type: INPUT_TYPE_LOCATION,
    label: 'form.user.input.location.autofill.label',
  },
  {
    type: INPUT_TYPE_DATETIME_DATE,
    label: 'form.user.input.datetime.date_picker.autofill.label',
  },
  {
    type: INPUT_TYPE_DATETIME_DATETIME,
    label: 'form.user.input.datetime.datetime_picker.autofill.label',
  },
  {
    type: INPUT_TYPE_DATETIME_DATE_RANGE,
    label: 'form.user.input.datetime.date_range_picker.autofill.label',
  },
  {
    type: INPUT_TYPE_DATETIME_DATETIME_RANGE,
    label: 'form.user.input.datetime.datetime_range_picker.autofill.label',
  },
]
