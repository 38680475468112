import { render, staticRenderFns } from "./VariablesForm.vue?vue&type=template&id=b2910010&scoped=true"
import script from "./VariablesForm.vue?vue&type=script&lang=js"
export * from "./VariablesForm.vue?vue&type=script&lang=js"
import style0 from "./VariablesForm.vue?vue&type=style&index=0&id=b2910010&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b2910010",
  null
  
)

export default component.exports