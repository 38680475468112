<template>
  <div class="configuration-form">
    <div class="columns">
      <div class="column">
        <b-field label="API Key / Revision ID" expanded>
          <b-field>
            <b-input
              v-model="currentConfigKey"
              placeholder="Configuration Key"
              expanded
            />
            <div class="control">
              <b-button
                type="is-primary"
                :disabled="!setConfigurationKeyEnabled"
                @click="$emit('setConfigKey', currentConfigKey)"
                >Set</b-button
              >
            </div>
          </b-field>
        </b-field>
      </div>

      <div class="column">
        <b-field label="Preconfigured Key">
          <b-select
            v-model="preconfiguredConfigKey"
            size="is-small"
            placeholder="Select Preconfigured Key"
            expanded
            @input="onPreconfiguredKeySelected"
          >
            <option
              v-for="key in preconfiguredConfigKeys"
              :key="key"
              :value="key"
            >
              {{ key }}
            </option>
          </b-select>
        </b-field>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <b-field label="Mode">
          <b-select
            v-model="mode"
            size="is-small"
            placeholder="Select Mode"
            expanded
            @input="onModeSelected"
          >
            <option
              v-for="option in modes"
              :key="option.value"
              :value="option.value"
            >
              {{ option.label }}
            </option>
          </b-select>
        </b-field>
      </div>

      <div class="column">
        <b-field label="Integration Type">
          <b-select
            v-model="integration"
            size="is-small"
            placeholder="Select Integration"
            expanded
            @input="onIntegrationSelected"
          >
            <option
              v-for="option in integrations"
              :key="option.value"
              :value="option.value"
            >
              {{ option.label }}
            </option>
          </b-select>
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-field label="External Variables Object Name" expanded>
          <b-field>
            <p class="control">
              <span class="button is-static">window.</span>
            </p>

            <b-input
              v-model="externalVariablesObjectName"
              :placeholder="defaultExternalVariablesObjectName"
              expanded
            />
            <div class="control">
              <b-button
                type="is-info"
                @click="
                  $emit(
                    'setExternalVariablesObjectName',
                    externalVariablesObjectName,
                  )
                "
                >Set</b-button
              >
            </div>
          </b-field>
        </b-field>
      </div>

      <div class="column">
        <b-field
          label="Whitelabel/Enterprise Integrator Name"
          :message="
            'Integrator config is fetched from ' +
            integratorConfigPath +
            '/<name>.json. Use empty string disable proxy.'
          "
          expanded
        >
          <b-field>
            <b-input
              v-model="currentIntegratorName"
              placeholder="Integrator Name"
              expanded
            />
            <div class="control">
              <b-button
                type="is-primary"
                @click="$emit('setIntegratorName', currentIntegratorName)"
                >Set</b-button
              >
            </div>
          </b-field>
        </b-field>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <b-field grouped>
          <b-field>
            <b-switch
              v-model="headerHidden"
              @input="$emit('setHeaderHidden', headerHidden)"
            >
              Header Hidden
            </b-switch>
          </b-field>

          <b-field>
            <b-switch
              v-model="launcherHidden"
              @input="$emit('setLauncherHidden', launcherHidden)"
            >
              Launcher Hidden
            </b-switch>
          </b-field>
        </b-field>

        <b-field grouped>
          <b-field>
            <b-switch
              v-model="closeDisabled"
              @input="$emit('setCloseDisabled', closeDisabled)"
            >
              Close Disabled
            </b-switch>
          </b-field>

          <b-field>
            <b-switch
              v-model="messageDelayDisabled"
              @input="$emit('setMessageDelayDisabled', messageDelayDisabled)"
            >
              Message Delay Disabled
            </b-switch>
          </b-field>
        </b-field>

        <b-field grouped>
          <b-field>
            <b-switch
              v-model="cookieless"
              @input="$emit('setCookieless', cookieless)"
            >
              Cookieless
            </b-switch>
          </b-field>
        </b-field>
      </div>
    </div>

    <div class="buttons">
      <b-button :disabled="!bootstrapped" @click="$emit('configure')"
        >Configure</b-button
      >
      <b-button :disabled="bootstrapped" @click="$emit('bootstrap')"
        >Bootstrap</b-button
      >
      <b-button
        type="is-danger"
        :disabled="!bootstrapped"
        outlined
        @click="$emit('teardown')"
        >Teardown</b-button
      >

      <b-button
        type="is-success"
        :disabled="!bootstrapped"
        outlined
        @click="$emit('openStandalone')"
        >Open Standalone</b-button
      >
    </div>
  </div>
</template>

<script>
import { DEFAULT_VARIABLES_OBJECT_NAME } from '@/common/common-constants'

export default {
  props: {
    configKey: {
      type: String,
      required: true,
    },
    preconfiguredConfigKeys: {
      type: Array,
      default: () => [],
    },
    bootstrapped: {
      type: Boolean,
      default: false,
    },
    integratorName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      currentConfigKey: this.configKey,
      preconfiguredConfigKey: null,
      currentIntegratorName: this.integratorName,
      integratorConfigPath: process.env.INTEGRATOR_CONFIG_PATH,
      externalVariablesObjectName: DEFAULT_VARIABLES_OBJECT_NAME,
      defaultExternalVariablesObjectName: DEFAULT_VARIABLES_OBJECT_NAME,
      integrations: [
        {
          value: 'html',
          label: 'HTML / Code Snippet',
        },
        {
          value: 'wordpress',
          label: 'Wordpress',
        },
      ],
      integration: 'html',
      modes: [
        {
          value: 'live',
          label: 'Live',
        },
        {
          value: 'live-preview',
          label: 'Story Builder Preview',
        },
        {
          value: 'preview',
          label: 'Theming Preview',
        },
      ],
      mode: 'live',
      headerHidden: false,
      launcherHidden: false,
      closeDisabled: false,
      messageDelayDisabled: false,
      cookieless: false,
    }
  },
  computed: {
    setConfigurationKeyEnabled() {
      return this.configKey && this.configKey.length > 0
    },
  },
  watch: {
    configKey: {
      immediate: true,
      handler(configKey) {
        this.currentConfigKey = configKey
        this.preconfiguredConfigKey = configKey

        if (!this.preconfiguredConfigKeys.includes(configKey)) {
          this.preconfiguredConfigKey = null
        }
      },
    },
    integratorName: {
      immediate: true,
      handler(integratorName) {
        this.currentIntegratorName = integratorName
      },
    },
  },
  methods: {
    onPreconfiguredKeySelected(key) {
      this.currentConfigKey = key
      this.$emit('input', this.currentConfigKey)
      this.$emit('setConfigKey', this.currentConfigKey)
    },
    onIntegrationSelected(integration) {
      this.integration = integration
      this.$emit('setIntegration', this.integration)
    },
    onModeSelected(mode) {
      this.mode = mode
      this.$emit('setMode', this.mode)
    },
  },
}
</script>
