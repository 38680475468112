<template>
  <div class="message-form">
    <b-tabs v-model="activeTab" type="is-boxed" vertical expanded>
      <b-tab-item label="Text / HTML">
        <b-field>
          <b-input
            v-model="text"
            type="textarea"
            placeholder="Text / HTML..."
            expanded
          />
        </b-field>
        <b-field>
          <button class="button is-primary" @click="sendMessage">Send</button>
        </b-field>
      </b-tab-item>

      <b-tab-item label="Button Group">
        <b-field>
          <b-input
            v-model="buttons"
            type="textarea"
            placeholder="Options as JSON String Array"
            expanded
          />
        </b-field>
        <b-field>
          <button class="button is-primary" @click="sendButtons">Send</button>
        </b-field>
      </b-tab-item>

      <b-tab-item label="Button List">
        <b-field>
          <b-input
            v-model="buttonList"
            type="textarea"
            placeholder="Options as JSON String Array"
            expanded
          />
        </b-field>
        <b-field>
          <button class="button is-primary" @click="sendButtonList">
            Send
          </button>
        </b-field>
      </b-tab-item>

      <b-tab-item label="Media">
        <div class="columns">
          <div class="column">
            <b-field label="Web Link" expanded>
              <b-field>
                <b-input v-model="link" placeholder="Link" expanded />
                <p class="control">
                  <button class="button is-primary" @click="sendLink">
                    Send
                  </button>
                </p>
              </b-field>
            </b-field>
          </div>

          <div class="column">
            <b-field label="Image Link" expanded>
              <b-field>
                <b-input
                  v-model="imageLink"
                  placeholder="Image Link"
                  expanded
                />
                <p class="control">
                  <button class="button is-primary" @click="sendImageLink">
                    Send
                  </button>
                </p>
              </b-field>
            </b-field>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <b-field label="Youtube Video Link" expanded>
              <b-field>
                <b-input
                  v-model="youtubeLink"
                  placeholder="Youtube Video Link"
                  expanded
                />
                <p class="control">
                  <button class="button is-primary" @click="sendYoutubeLink">
                    Send
                  </button>
                </p>
              </b-field>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Vimeo Video Link" expanded>
              <b-field>
                <b-input
                  v-model="vimeoLink"
                  placeholder="Vimeo Video Link"
                  expanded
                />
                <p class="control">
                  <button class="button is-primary" @click="sendVimeoLink">
                    Send
                  </button>
                </p>
              </b-field>
            </b-field>
          </div>
        </div>
      </b-tab-item>

      <b-tab-item label="User Input">
        <b-field expanded>
          <b-select v-model="userInputType" placeholder="Type" expanded>
            <option v-for="type of userInputTypes" :key="type" :value="type">
              {{ type }}
            </option>
          </b-select>
          <b-input v-model="userInputMaxLength" type="number" placeholder="0" />
          <p class="control">
            <button class="button is-primary" @click="sendUserInput">
              Send
            </button>
          </p>
        </b-field>
      </b-tab-item>

      <b-tab-item label="Survey">
        <div class="buttons">
          <b-button @click="sendSurvey('nps')">NPS</b-button>
          <b-button @click="sendSurvey('csat')">CSAT</b-button>
          <b-button @click="sendSurvey('emoji')">Emoji</b-button>
          <b-button @click="sendSurvey('stars')">Stars</b-button>
        </div>
      </b-tab-item>

      <b-tab-item label="Intent">
        <b-button type="is-primary" @click="sendIntent">Send</b-button>
      </b-tab-item>

      <b-tab-item label="Info">
        <b-field expanded>
          <b-select v-model="infoType" placeholder="Chat Info Type" expanded>
            <option v-for="type of infoTypes" :key="type" :value="type">
              {{ type }}
            </option>
          </b-select>
          <p class="control">
            <button class="button is-primary" @click="sendInfo">Send</button>
          </p>
        </b-field>
      </b-tab-item>

      <b-tab-item label="Workflow">
        <b-field>
          <b-input
            v-model="workflowPaths"
            type="textarea"
            placeholder="Paths as JSON String Array"
            expanded
          />
        </b-field>
        <b-field>
          <button class="button is-primary" @click="sendWorkflow">Send</button>
        </b-field>
      </b-tab-item>

      <b-tab-item label="File Upload">
        <b-field label="Max Size (MB)">
          <b-numberinput v-model="fileUploadMaxSize" min="1" max="50" />
        </b-field>
        <b-field label="Number of Files">
          <b-numberinput v-model="fileUploadMaxFiles" min="1" max="10" />
        </b-field>

        <b-field label="Accepted Types">
          <div class="block">
            <b-checkbox
              v-model="fileUploadAcceptedTypes"
              :native-value="fileTypeAll"
              @input="onAllAcceptedFileTypeSelected"
            >
              All
            </b-checkbox>
            <b-checkbox
              v-model="fileUploadAcceptedTypes"
              :native-value="fileTypeDocument"
              @input="onSpecificAcceptedFileTypeSelected"
            >
              Documents
            </b-checkbox>
            <b-checkbox
              v-model="fileUploadAcceptedTypes"
              :native-value="fileTypeImage"
              @input="onSpecificAcceptedFileTypeSelected"
            >
              Images
            </b-checkbox>
            <b-checkbox
              v-model="fileUploadAcceptedTypes"
              :native-value="fileTypeVideo"
              @input="onSpecificAcceptedFileTypeSelected"
            >
              Videos
            </b-checkbox>
          </div>
        </b-field>

        <b-field>
          <button class="button is-primary" @click="sendFileUpload">
            Send
          </button>
        </b-field>
      </b-tab-item>
    </b-tabs>

    <div class="buttons">
      <b-button type="is-danger" outlined @click="$emit('clear')"
        >Clear</b-button
      >
    </div>
  </div>
</template>

<script>
import WebsocketMessageFactory from '@/common/WebsocketMessageFactory'
import { INPUT_TYPES } from '@/chatbot/models/InputTypes'
import { INFO_TYPES } from '@/chatbot/models/InfoTypes'
import {
  FILE_TYPES,
  FILE_TYPE_ALL,
  FILE_TYPE_DOCUMENT,
  FILE_TYPE_IMAGE,
  FILE_TYPE_VIDEO,
} from '@/chatbot/models/FileTypes'

export default {
  data() {
    return {
      activeTab: null,
      text: '<p>Lorem ipsum dolor sit amet.</p>',
      buttons: JSON.stringify(['Option 1', 'Option 2', 'Option 3']),
      buttonList: JSON.stringify(['Option 1', 'Option 2', 'Option 3']),
      link: 'https://www.aiaibot.com',
      youtubeLink: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
      vimeoLink: 'https://vimeo.com/27246366',
      imageLink: 'https://media.giphy.com/media/osjgQPWRx3cac/giphy.gif',
      userInputMaxLength: 255,
      userInputType: 'text',
      infoType: 'please-wait',
      workflowPaths: JSON.stringify([
        'Processing Delivery',
        'Out for Delivery',
        'Delivered',
      ]),
      fileUploadMaxSize: 100,
      fileUploadMaxFiles: 5,
      fileUploadAcceptedTypes: [FILE_TYPE_ALL],
    }
  },
  computed: {
    userInputTypes() {
      return INPUT_TYPES
    },
    infoTypes() {
      return INFO_TYPES
    },
    fileTypes() {
      return FILE_TYPES
    },
    fileTypeAll() {
      return FILE_TYPE_ALL
    },
    fileTypeDocument() {
      return FILE_TYPE_DOCUMENT
    },
    fileTypeImage() {
      return FILE_TYPE_IMAGE
    },
    fileTypeVideo() {
      return FILE_TYPE_VIDEO
    },
  },
  methods: {
    sendMessage() {
      this.$emit('message', WebsocketMessageFactory.html(this.text))
    },
    sendButtons() {
      const buttons = JSON.parse(this.buttons)
      const message = WebsocketMessageFactory.button(buttons)

      this.$emit('message', message)
    },
    sendButtonList() {
      const buttons = JSON.parse(this.buttonList)
      const message = WebsocketMessageFactory.buttonList(buttons)

      this.$emit('message', message)
    },
    sendLink() {
      const message = WebsocketMessageFactory.link(this.link, 'This is a link.')

      this.$emit('message', message)
    },
    sendImageLink() {
      const message = WebsocketMessageFactory.image(this.imageLink, 'Image')

      this.$emit('message', message)
    },
    sendYoutubeLink() {
      const message = WebsocketMessageFactory.youtube(
        this.youtubeLink,
        'Youtube Video',
      )

      this.$emit('message', message)
    },
    sendVimeoLink() {
      const message = WebsocketMessageFactory.vimeo(
        this.vimeoLink,
        'Vimeo Video',
      )

      this.$emit('message', message)
    },
    sendUserInput() {
      const message = WebsocketMessageFactory.userInput(
        this.userInputType,
        this.userInputMaxLength,
      )

      this.$emit('message', message)
    },
    sendSurvey(type = 'nps') {
      const message = WebsocketMessageFactory.survey(type)

      this.$emit('message', message)
    },
    sendIntent() {
      const message = WebsocketMessageFactory.intent()

      this.$emit('message', message)
    },
    sendInfo() {
      const message = WebsocketMessageFactory.info(this.infoType)

      this.$emit('message', message)
    },
    sendWorkflow() {
      const paths = JSON.parse(this.workflowPaths)
      const message = WebsocketMessageFactory.workflow(paths)

      this.$emit('message', message)
    },
    sendFileUpload() {
      const message = WebsocketMessageFactory.fileUpload(
        this.fileUploadMaxSize,
        this.fileUploadMaxFiles,
        this.fileUploadAcceptedTypes,
      )

      this.$emit('message', message)
    },
    onAllAcceptedFileTypeSelected() {
      this.fileUploadAcceptedTypes = [FILE_TYPE_ALL]
    },
    onSpecificAcceptedFileTypeSelected() {
      this.fileUploadAcceptedTypes = this.fileUploadAcceptedTypes.filter(
        (type) => type !== FILE_TYPE_ALL,
      )
    },
  },
}
</script>
