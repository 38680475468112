<template>
  <div class="event-form">
    <b-field label="Custom Event" expanded>
      <b-field>
        <b-input v-model="event" placeholder="custom-event-name" expanded />
        <div class="control">
          <b-button
            type="is-primary"
            :disabled="!triggerButtonEnabled"
            @click="$emit('trigger', event)"
            >Trigger</b-button
          >
        </div>
      </b-field>
    </b-field>

    <div class="buttons">
      <a href="https://www.aiaibot.com" class="button"
        >Trigger <code class="has-background-white">unload</code> event</a
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      event: '',
    }
  },
  computed: {
    triggerButtonEnabled() {
      return this.event.length > 0
    },
  },
}
</script>
