<template>
  <div class="debug-form">
    <div class="fields">
      <b-field>
        <b-switch
          v-model="showFrameArea"
          @input="$emit('setFrameAreaVisible', showFrameArea)"
        >
          Show Frame Area
        </b-switch>
      </b-field>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showFrameArea: false,
    }
  },
}
</script>

<style lang="scss" scoped>
.fields {
  margin-bottom: 4 * $nudge;
}
.buttons code {
  padding-top: 0;
  padding-bottom: 0;
}
</style>
