<template>
  <div class="variables-form">
    <b-field>
      <b-input
        v-model="variables"
        type="textarea"
        placeholder="Variables as JSON Object"
        expanded
      />
    </b-field>
    <b-field>
      <div class="is-flex variables-form--buttons">
        <div class="buttons">
          <button class="button is-primary" @click="setVariables(true)">
            Set via API
          </button>

          <button class="button is-primary" @click="setVariables(false)">
            Set via DOM (Reloads Scripts)
          </button>
        </div>

        <button
          class="button is-primary is-outlined"
          @click="setExampleVariables"
        >
          Add Examples
        </button>
      </div>
    </b-field>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      variables: '',
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.variables = JSON.stringify(value)
      },
    },
  },
  methods: {
    setVariables(useApi) {
      this.$emit('update', { variables: JSON.parse(this.variables), useApi })
    },
    setExampleVariables() {
      this.variables = JSON.stringify({
        current_time: new Date().toISOString(),
        hostname: window.location.host,
        name: 'Jon Doe',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.variables-form--buttons {
  justify-content: space-between;
}
</style>
