<template>
  <div class="chat">
    <div class="buttons">
      <b-button @click="$emit('open')">Open</b-button>
      <b-button @click="$emit('close')">Close</b-button>

      <b-button @click="$emit('positionRight')">Position Right</b-button>
      <b-button @click="$emit('positionLeft')">Position Left</b-button>

      <b-button @click="$emit('show')">Show</b-button>
      <b-button @click="$emit('hide')"> Hide</b-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mode: 'live',
    }
  },
}
</script>
