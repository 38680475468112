<template>
  <div class="dashboard">
    <section class="container is-widescreen is-fullhd dashboard--info">
      <div class="container">
        <ChatInfo />
      </div>
    </section>

    <div class="section">
      <section class="container dashboard--controls">
        <h1 class="title">
          Chatbot Dashboard
          <b-button
            size="is-small"
            class="expand-btn"
            :icon-right="fullWidth ? 'arrow-collapse' : 'arrow-expand'"
            @click="fullWidth = !fullWidth"
          />
        </h1>
        <ChatControls :is-full-width="fullWidth" />
      </section>
    </div>
  </div>
</template>

<script>
import ChatControls from '@/dashboard/components/ChatControls'
import ChatInfo from '@/dashboard/components/ChatInfo'

export default {
  components: {
    ChatInfo,
    ChatControls,
  },
  data() {
    return {
      fullWidth: false,
    }
  },
}
</script>

<style lang="scss" scoped>
.dashboard {
  &--info {
    background: white;
    padding: 2 * $nudge 0;
    border-bottom: 1px solid $grey-300;
  }
}

.expand-btn {
  height: unset;
}
</style>
