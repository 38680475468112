<template>
  <div class="conversations-form is-flex">
    <b-loading :is-full-page="false" :active="!connected">
      <div class="connect-overlay text-center">
        <h4>You are not connected yet.</h4>
        <b-button type="is-primary" @click="onConnect">
          Connect to Conversations API
        </b-button>
      </div>
    </b-loading>
    <div class="conversation-actions">
      <h3 class="title is-6">Chat info</h3>
      <div class="field">
        <b class="ll">Connected: </b>
        <span v-text="connected ? 'true' : 'false'" />
      </div>
      <div v-if="user" class="field">
        <p class="ll">User ID:</p>
        <span v-text="user.id" />
      </div>
      <div v-if="user" class="field">
        <p class="ll">User name:</p>
        <span v-text="user.name" />
      </div>

      <label for="conversationsFormConversationId">Conversation ID</label>
      <b-input
        id="conversationsFormConversationId"
        v-model="conversationId"
        placeholder="Conversation ID"
        size="is-small"
        class="mb-2"
        expanded
      />
    </div>
    <div class="chat">
      <div class="conversation-messages">
        <div
          v-for="(message, i) in messages"
          :key="`message--${i}`"
          class="message"
          :class="[`message--type-${message.type}`]"
        >
          <b
            v-if="message.userId"
            class="message-user"
            v-text="message.userId"
          />
          <p v-text="message.content" />
        </div>
      </div>
      <div class="chat-input is-flex">
        <b-input
          v-model="input"
          :placeholder="user ? 'Enter your message' : 'Enter your name'"
          type="text"
          class="input-field"
          expanded
          @keypress.native.enter="onInput(input)"
        />

        <b-button type="is-primary" :loading="sending" @click="onInput(input)">
          {{ user ? 'Send' : 'Join' }}
        </b-button>
        <b-button
          v-if="user"
          class="leave-btn"
          type="is-danger"
          @click="onLeave"
        >
          Leave
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import io from 'socket.io-client'
import {
  EVENT_CONV_MESSAGE,
  EVENT_CONV_USER_JOIN,
  EVENT_CONV_USER_LEAVE,
  EVENT_CONV_USER_JOINED,
  EVENT_CONV_USER_LEFT,
} from '@/common/socketio-events'

export default {
  data() {
    return {
      socket: null,
      connected: false,
      sending: false,
      conversationId: '',
      user: null,
      messages: [],
      input: '',
    }
  },
  watch: {
    async messages(val) {
      await this.$nextTick()
      const $ctn = this.$el.querySelector('.conversation-messages')

      $ctn.scrollTop = $ctn.scrollHeight
    },
  },
  created() {
    // TODO change to new DOPS-266 thing
    this.socket = io(process.env.BASE_CONVERSATION_HOST, {
      path: process.env.BASE_CONVERSATION_PATH,
      forceNew: true,
      transports: ['websocket'],
      autoConnect: false,
    })

    // helper func
    this.socket.request = (event, ...args) =>
      new Promise((resolve, reject) => {
        let timedOut = false

        const timeout = setTimeout(() => {
          timedOut = true
          reject(new Error('Timed out'))
        }, 60e3)

        this.socket.emit(event, ...args, (err, data) => {
          if (timedOut) return

          clearTimeout(timeout)

          if (err) {
            reject(err)

            return
          }

          resolve(data)
        })
      })

    this.socket.on('connect', this.socketOnConnect)
    this.socket.on('disconnect', this.socketOnDisconnect)
    this.socket.on('error', this.onError)

    this.socket.on(EVENT_CONV_MESSAGE, this.socketOnMessage)
    this.socket.on(EVENT_CONV_USER_JOINED, this.socketOnUserJoined)
    this.socket.on(EVENT_CONV_USER_LEFT, this.socketOnUserLeft)
  },
  beforeDestroy() {
    if (this.socket?.connected) {
      this.socket.close()
    }
  },
  methods: {
    onError(error) {
      let message = error

      if (error?.errors) {
        message = error.errors
          .map((err) => `[${err.domain}] ${err.reason}`)
          .join(', ')
      }

      if (typeof message === 'object') {
        message = JSON.stringify(message)
      }

      this.messages.push({
        type: 'error',
        content: `Error received: ${message}`,
      })
    },
    async onJoin(username) {
      try {
        const { you: user } = await this.socket.request(EVENT_CONV_USER_JOIN, {
          conversation: this.conversationId,
          user: { name: username },
        })

        this.user = user

        this.messages.push({
          type: 'info',
          content: `User ${user.name} joined the chat.`,
        })
      } catch (error) {
        this.onError(error)
      }
    },
    async onLeave() {
      try {
        const user = await this.socket.request(EVENT_CONV_USER_LEAVE, {
          conversation: this.conversationId,
          user: this.user.id,
        })

        this.user = null

        this.messages.push({
          type: 'info',
          content: `User ${user.name} left the chat.`,
        })
      } catch (error) {
        this.onError(error)
      }
    },
    async onInput(message = this.input) {
      if (!message?.length) return

      if (!this.user) {
        await this.onJoin(message)
        this.input = ''

        return
      }

      try {
        this.sending = true

        const msg = await this.socket.request(EVENT_CONV_MESSAGE, {
          conversation: this.conversationId,
          user: this.user.id,
          text: message,
        })

        this.messages.push({
          type: 'user-message',
          content: msg.text,
          userId: msg.user?.name,
        })

        this.input = ''
      } catch (error) {
        this.onError(error)
      } finally {
        this.sending = false
      }
    },
    onConnect() {
      this.socket.connect()
    },
    // SOCKET HANDLERS
    socketOnConnect() {
      this.connected = true
    },
    socketOnDisconnect() {
      this.connected = false
    },
    socketOnMessage(msg) {
      if (Buffer.isBuffer(msg)) {
        this.messages.push({
          type: 'user-message',
          content: msg.text,
          userId: msg.user?.name,
        })
      }
    },
    socketOnUserJoined(user) {
      if (Buffer.isBuffer(user)) {
        this.messages.push({
          type: 'info',
          content: `User ${user.name} joined the chat.`,
        })
      }
    },
    socketOnUserLeft(user) {
      if (Buffer.isBuffer(user)) {
        this.messages.push({
          type: 'info',
          content: `User ${user.name} left the chat.`,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.conversations-form {
  position: relative;
}

.conversations-form--buttons {
  justify-content: space-between;
}

.conversation-actions {
  flex: 1 1 40%;
  background: $grey-100;
  max-width: 350px;
  padding: $sp-20 $sp-24;
  margin-right: $sp-12;

  .field {
    margin-bottom: $sp-12;
    font-size: 14px;

    .ll {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
}

.chat {
  flex: 1 1 80%;
  background: $grey-200;
  display: flex;
  flex-direction: column;

  .conversation-messages {
    flex-grow: 1;
    height: 400px;
    overflow-y: scroll;
    padding: $sp-16;

    .message {
      padding: $sp-8 $sp-12;
      background: $white;
      color: $black;
      margin-bottom: $sp-12;

      &--type-error {
        background: $red-700;
        color: $red-100;
      }

      &--type-info {
        background: $grey-400;
        color: white;
        text-align: center;
        font-weight: 600;
      }
    }
  }

  .chat-input {
    padding: $sp-12;
    width: 100%;

    .input-field {
      flex-grow: 1;
      margin-right: $sp-12;
    }

    .leave-btn {
      margin-left: $sp-8;
    }
  }
}

.text-center {
  text-align: center;
}

.connect-overlay {
  h4 {
    margin-bottom: $sp-12;
  }
}
.mb-2 {
  margin-bottom: $sp-8;
}
</style>
