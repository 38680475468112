export const EVENT_CLOSE = 'event-chat-close'
export const EVENT_GOTO_STORY = 'goto_story'
export const EVENT_OPEN = 'event-chat-open'
export const EVENT_STORY_MESSAGE = 'message'
export const EVENT_TRIGGER = 'trigger'
export const EVENT_SESSION = 'session'
export const EVENT_SESSION_START = 'start_session'
export const EVENT_SESSION_END = 'session_end'
export const EVENT_METADATA = 'metadata'
export const EVENT_INTERACTION = 'interaction'
export const EVENT_GENERAL = 'event'
export const EVENT_CONSENT_ACCEPTED = 'consent_accepted'
export const EVENT_CONSENT_DECLINED = 'consent_rejected'
export const EVENT_SET_LANGUAGE = 'set_user_language'

export const EVENT_CONV_LISTEN = 'join'
export const EVENT_CONV_USER_JOIN = 'user_join'
export const EVENT_CONV_USER_REJOIN = 'user_rejoin'
export const EVENT_CONV_USER_JOINED = 'user_joined'
export const EVENT_CONV_USER_LEAVE = 'user_leave'
export const EVENT_CONV_USER_LEFT = 'user_left'
export const EVENT_CONV_MESSAGE = 'message'
export const EVENT_CONV_END = 'conversation_ended'
export const EVENT_CONV_START = 'conversation_started'
