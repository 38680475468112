export const INFO_TYPE_LIVE_CHAT_ENDED = 'live-chat-ended'
export const INFO_TYPE_LIVE_CHAT_JOINED = 'live-chat-joined'
export const INFO_TYPE_LIVE_CHAT_LEFT = 'live-chat-left'
export const INFO_TYPE_WAITING_FOR_ANSWER = 'please-wait'
export const INFO_TYPE_FILE_UPLOADED = 'file-uploaded'
export const INFO_SESSION_ONCE_PREVIEW = 'once-a-session-preview'
export const INFO_SESSION_TIMESTAMP = 'timestamp'

export const INFO_TYPES = [
  INFO_TYPE_LIVE_CHAT_ENDED,
  INFO_TYPE_LIVE_CHAT_JOINED,
  INFO_TYPE_LIVE_CHAT_LEFT,
  INFO_TYPE_WAITING_FOR_ANSWER,
  INFO_TYPE_FILE_UPLOADED,
  INFO_SESSION_ONCE_PREVIEW,
  INFO_SESSION_TIMESTAMP,
]
