<template>
  <div class="behaviour-form">
    <div class="columns">
      <div class="column">
        <b-field label="Closing">
          <b-switch v-model="behaviour.closeDisabled" @input="change">
            Closing disabled
          </b-switch>
        </b-field>
      </div>

      <div class="column">
        <b-field label="Message Delay">
          <b-switch v-model="behaviour.messageDelayDisabled" @input="change">
            No Message Delay
          </b-switch>
        </b-field>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <b-field label="Fullscreen toggling">
          <b-switch v-model="behaviour.fullscreenAllowToggle" @input="change">
            Allow Fullscreen toggling
          </b-switch>
        </b-field>
      </div>

      <div class="column">
        <b-field label="Fullscreen">
          <b-switch v-model="behaviour.openInFullscreen" @input="change">
            Open in Fullscreen
          </b-switch>
        </b-field>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <b-field label="Story Handling">
          <b-switch v-model="behaviour.resetOnClose" @input="change">
            Reset Story on Close
          </b-switch>
        </b-field>
      </div>

      <!-- Cookieless setting is not in here because it requires re-bootstrap -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      behaviour: this.value,
    }
  },
  methods: {
    change() {
      this.$emit('update', {
        ...this.behaviour,
      })
    },
  },
}
</script>
