<template>
  <div class="notification chat-info">
    <span class="version">
      <span>Version: </span>
      <span class="is-family-code" v-text="version" />
    </span>

    <span class="revision">
      <span>Revision: </span>
      <span class="is-family-code" v-text="revision" />
    </span>

    <span class="environment">
      <span>Environment: </span>
      <span class="is-family-code" v-text="environment" />
    </span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      version: process.env.VERSION,
      revision: process.env.REVISION,
      environment: process.env.NODE_ENV,
    }
  },
}
</script>

<style lang="scss" scoped>
.chat-info {
  font-size: $size-7;

  .version,
  .revision,
  .last-checked {
    margin-right: 2 * $nudge;
  }
}
</style>
