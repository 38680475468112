import { v4 as uuidv4 } from 'uuid'

export default class WebsocketMessageFactory {
  static create(payloadType = 'html', payload) {
    return {
      id: uuidv4(),
      payloadType,
      payload,
    }
  }

  static html(content) {
    return this.create('html', { content })
  }

  static button(buttons = []) {
    return this.create('button-group', { buttons })
  }

  static buttonList(buttons = [], heading = 'test') {
    return this.create('button-list', { heading, buttons })
  }

  static userInput(inputType = 'text', maxLength = 255) {
    return this.create('user-input', { inputType, maxLength })
  }

  static media(mediaType = 'link', url = '', description = '') {
    return this.create('media', { mediaType, url, description })
  }

  static link(url, description) {
    return this.media('link', url, description)
  }

  static image(url, description) {
    return this.media('image', url, description)
  }

  static youtube(url, description) {
    return this.media('youtube', url, description)
  }

  static vimeo(url, description) {
    return this.media('vimeo', url, description)
  }

  static survey(surveyType) {
    return this.create('survey', { surveyType })
  }

  static nps() {
    return this.survey('nps')
  }

  static csat() {
    return this.survey('csat')
  }

  static emoji() {
    return this.survey('emoji')
  }

  static stars() {
    return this.survey('stars')
  }

  static intent() {
    return this.create('intent', {})
  }

  static info(slug) {
    return this.create('info', {
      slug,
    })
  }

  static workflow(paths) {
    return this.create('workflow', {
      paths,
    })
  }

  static fileUpload(maxFileSize = 50, maxFiles = 1, allowedTypes = 'all') {
    return this.create('file-upload', {
      maxFileSize,
      maxFiles,
      allowedTypes,
    })
  }
}
